<template>
  <validation-form
    ref="validationForm"
    v-slot="{ observer }"
    :default-submit-buttons="false"
    @validated="updateDivision"
  >
    <Modal
      title="Edit Division"
      :value="value"
      :submit-buttons="{
        loading: form.isLoading,
        invalid: observer.failed
      }"
      @show="Object.assign(form, division)"
      @save="$refs.validationForm.validate()"
      v-on="$listeners"
    >
      <form-division
        layout="modal"
        :division="form"
      />
    </Modal>
  </validation-form>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import FormDivision from '@/components/forms/FormDivision.vue'
import Form from '@/forms/Form'

export default {
  components: {
    Modal,
    FormDivision,
    ValidationForm,
  },
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    division: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      form: new Form({
        name: '',
        order: null,
        active: true,
      }),
    }
  },
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    updateDivision() {
      this.form.put(`/divisions/${this.division.id}`)
        .then(response => {
          const { data } = response
          this.$emit('update-division', data)
        })
    },
  },
}
</script>
