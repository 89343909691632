<template>
  <modal-card title="Add Division">
    <template #default="{ isCard }">
      <validation-form
        ref="validationForm"
        v-slot="{ observer }"
        :default-submit-buttons="false"
        @validated="createDivision"
      >
        <form-division
          ref="divisionForm"
          :division="form"
          :layout="isCard ? 'card' : 'modal'"
        >
          <portal
            :disabled="isCard"
            to="modal-footer-add-division"
          >
            <submit-buttons
              class="mt-sm-2"
              :loading="form.isLoading"
              :invalid="observer.failed"
              @save="$refs.validationForm.validate()"
              v-on="$listeners"
            />
          </portal>
        </form-division>
      </validation-form>
    </template>

    <template #modal-footer>
      <portal-target name="modal-footer-add-division" />
    </template>
  </modal-card>
</template>

<script>
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import ModalCard from '@/components/modals/ModalCard.vue'
import FormDivision from '@/components/forms/FormDivision.vue'
import SubmitButtons from '@/components/buttons/SubmitButtons.vue'

import Form from '@/forms/Form'
import { mapState } from 'vuex'

export default {
  components: {
    ValidationForm,
    ModalCard,
    FormDivision,
    SubmitButtons,
  },
  data() {
    return {
      form: new Form({
        name: '',
        order: null,
        active: true,
      }),
    }
  },
  computed: mapState('event/seasons', ['season']),
  mounted() {
    this.form.validationObserver = this.$refs.validationForm.$refs.observer
  },
  methods: {
    createDivision() {
      this.form.post(`/seasons/${this.season.id}/divisions`, true)
        .then(response => {
          document.getElementById('name').focus()

          this.$emit('add-division', response.data)
        })
    },
  },
}
</script>
