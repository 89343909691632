<template>
  <b-form-row>
    <!-- Name -->
    <b-col sm="3">
      <form-validation-field
        v-model="division.name"
        vid="name"
        name="division-name"
        label="Name*"
        placeholder="Name"
        aria-autocomplete="none"
        rules="required|is_not:No Division|is_not:no division"
        :validation="{ customMessages: { 'is_not': 'This is a reserved name.' }}"
        autofocus
      />
    </b-col>

    <!-- Order -->
    <b-col sm="2">
      <form-validation-field
        v-model="division.order"
        vid="order"
        name="division-order"
        label="Order"
        placeholder="Order"
        type="number"
        rules="integer"
      >
        Order
      </form-validation-field>
    </b-col>

    <!-- Active -->
    <b-col
      sm="auto"
      class="mx-1"
    >
      <b-form-group
        label="Active"
        label-for="active"
      >
        <b-form-checkbox
          id="active"
          v-model="division.active"
          switch
          class="mt-50"
          name="division-active"
        />
      </b-form-group>
    </b-col>

    <slot />
  </b-form-row>
</template>

<script>
import {
  BFormRow, BCol, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    BFormRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    FormValidationField,
  },
  props: {
    division: {
      required: true,
      type: Object,
    },
    layout: {
      type: String,
      default: 'modal',
      validator(value) {
        // The value must match one of these strings
        return ['card', 'modal'].includes(value)
      },
    },
  },
}
</script>

<style scoped>

</style>
