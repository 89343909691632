<template>
  <section v-if="season">
    <!-- Add New Division -->
    <add-division
      v-if="addDivision"
      @cancel="addDivision = false"
      @add-division="handleAddDivision"
    />

    <!-- Edit Division -->
    <edit-division
      v-model="editDivision"
      :division="division"
      @cancel="editDivision = false"
      @update-division="handleUpdateDivision"
    />

    <b-card no-body>
      <card-header-filters
        title="Divisions"
        :button="{ show: !addDivision }"
        :filters="filters"
        @add="addDivision = true"
        @reset-filters="filters = $event"
      >
        <template #filters>
          <!-- Status Filter -->
          <b-form-group
            label="Status"
            label-for="status-filter"
          >
            <b-form-select
              id="status-filter"
              v-model="filters.status"
              :options="statuses"
            />
          </b-form-group>
        </template>
      </card-header-filters>

      <!-- Divisions List -->
      <table-list
        primary-key="id"
        :items="filteredDivisions"
        :fields="fields"
        :endpoints="{ delete: '/divisions' }"
        :busy="isLoading"
        item-name="division"
        @link-clicked="showEditDivision"
      >
        <!-- Active cell -->
        <template #cell(active)="{ item }">
          <b-badge :variant="`light-${item.active ? 'success' : 'danger'}`">
            <span>{{ item.active ? 'Active' : 'Inactive' }}</span>
          </b-badge>
        </template>
      </table-list>
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BBadge, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import AddDivision from '@/views/events/manage/divisions/EventManageDivisionAdd.vue'
import EditDivision from '@/views/events/manage/divisions/EventManageDivisionEdit.vue'
import CardHeaderFilters from '@/components/cards/CardHeaderFilters.vue'
import TableList from '@/components/TableList.vue'

import { mapActions, mapState } from 'vuex'
import useFlash from '@/composables/useFlash'

const { flashError } = useFlash()

export default {
  name: 'EventDivisions',
  components: {
    BCard,
    BBadge,
    BFormGroup,
    BFormSelect,
    AddDivision,
    EditDivision,
    CardHeaderFilters,
    TableList,
  },
  data() {
    return {
      addDivision: false,
      editDivision: false,
      division: {},
      isLoading: true,
      fields: [
        'name',
        'order',
        {
          key: 'active',
          label: 'status',
        },
      ],
      statuses: [
        'All',
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
      filters: {
        status: 'All',
      },
    }
  },
  computed: {
    ...mapState('event/seasons', ['seasons', 'season']),
    ...mapState('event/divisions', ['divisions']),
    filteredDivisions() {
      if (this.filters.status === 'All') {
        return this.divisions
      }

      return this.divisions.filter(division => division.active === this.filters.status)
    },
  },
  watch: {
    season: {
      immediate: true,
      async handler(season) {
        if (!season) {
          return
        }

        this.isLoading = true

        try {
          await this.getDivisions({ season: season.id })
        } catch (error) {
          flashError(error)
          this.$Progress.fail()
        }

        this.isLoading = false
        this.$Progress.finish()
      },
    },
  },
  methods: {
    ...mapActions('event/divisions', ['getDivisions']),
    showEditDivision(division) {
      this.editDivision = true
      this.division = division
    },
    handleAddDivision(division) {
      this.divisions.push(division)
      this.sortDivisions()
    },
    handleUpdateDivision(division) {
      this.divisions.splice(
        this.divisions.findIndex(d => d.id === division.id),
        1,
        division,
      )

      this.sortDivisions()
      this.editDivision = false
    },
    sortDivisions() {
      // Resort divisions alphabetically.
      this.divisions.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()

        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        // names must be equal
        return 0
      })

      // Resort divisions by order.
      this.divisions.sort((a, b) => {
        const nameA = a.order
        const nameB = b.order

        if (nameA < nameB) { return -1 }
        if (nameA > nameB) { return 1 }
        // names must be equal
        return 0
      })
    },
  },
}
</script>

<style scoped>

</style>
